import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  IconButton,
  Switch,
} from "@mui/material";
import CountrySelect from "components/CountrySelect/CountrySelect";
import FormField from "components/FormField/FormField";
import LogoUpload from "components/LogoUpload/LogoUpload";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import StateSelect from "components/StateSelect/StateSelect";
import {
  schema as CompanyCreateSchema,
  getDefaultValues as createGetDefaultValues,
  defaultValuesSecondaryContact,
} from "DDD/action-objects/CompanyCreate";
import {
  schema as CompanyUpdateSchema,
  getDefaultValues as updateGetDefaultValues,
} from "DDD/action-objects/CompanyUpdate";
import {
  Company,
  CompanyStatus,
  CreateCompanyContactInput,
  CreateCompanyInput,
  OpportunitySource,
  UpdateCompanyInput,
  NotificationTriggerType,
  UpsertCompanyContactInput,
} from "generated/graphql";
import React, { useCallback, useState, useEffect } from "react";
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import CustomSelect from "../../Shared/CustomSelect/CustomSelect";
import { enumToValueOptions } from "../../../utils/enums/enumToValueOptions";
import PhoneInput from "components/PhoneInput/PhoneInput";
import WYSIWYG from "components/WYSIWYG/WYSIWYG";

type Action = "create" | "upsert";

interface ICompanyFormProps {
  action: Action;
  company: Company;
  onSubmit: SubmitHandler<CreateCompanyInput | UpdateCompanyInput>;
  onSuccess: (data: Company) => void;
  loading: boolean;
}

type FormData = {
  create: CreateCompanyInput;
  upsert: UpdateCompanyInput;
};

const schemas = {
  create: CompanyCreateSchema,
  upsert: CompanyUpdateSchema,
};

const notificationTypes = Object.values(NotificationTriggerType);

const getDefaultValues = (action: Action) => (initial: Company) => {
  if (action === "create") {
    return createGetDefaultValues(initial);
  } else {
    return updateGetDefaultValues(initial);
  }
};

const CompanyForm = ({ action, company, onSubmit, onSuccess, loading }: ICompanyFormProps) => {
  // @ts-ignore
  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { errors },
  } = useForm<FormData[typeof action]>({
    resolver: zodResolver(schemas[action]),
    // @ts-ignore
    defaultValues: getDefaultValues(action)(company),
  });

  const [localLoading, setLocalLoading] = useState(false);
  const watchLogo = watch("logo");
  const { fields, append, remove } = useFieldArray({
    control,
    name: `contacts.${action}`,
  });

  const isNotificationSelected = (type) => {
    const companyNotificationTypes = getValues("notificationTypes");
    if (companyNotificationTypes !== null) {
      return Boolean(companyNotificationTypes[type]);
    } else {
      return true;
    }
  };

  const updatePrimaryFields = useCallback(
    (index) => {
      setLocalLoading(true);
      fields.forEach((field, i) => {
        if (index !== i) {
          setValue(`contacts.upsert.${i}.primary`, false);
        }
      });
      setLocalLoading(false);
    },
    [fields, setLocalLoading]
  );

  const isLoading = loading || localLoading;

  const handleCopyInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const contactValues = getValues("contacts.upsert.0");
    if (checked) {
      setValue("contacts.upsert.0", {
        ...contactValues,
        phone: getValues("phone"),
        email: getValues("email"),
      } as UpsertCompanyContactInput);
    }
  };

  const phoneInputStyles = {
    "& fieldset": {
      border: "none",
      borderBottom: "1px solid #d2d6da",
      borderRadius: 0,
    },
    " & input": {
      padding: "2px",
    },
    " & > div": {
      lineHeight: "22px !important",
    },
  };

  const handleNotificationChange = (name, checked) => {
    const notifications = getValues("notificationTypes");
    const newNotifications = { ...notifications, [name]: checked };
    setValue("notificationTypes", newNotifications);
    trigger("notificationTypes");
  };

  return (
    <div style={{ position: "relative", zIndex: 1 }}>
      {isLoading && (
        <MDBox
          position="absolute"
          left={0}
          top={0}
          right={0}
          bottom={0}
          zIndex={10}
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ background: "rgba(50, 50, 100, 0.15)" }}
          borderRadius="12px"
        >
          <CircularProgress color="inherit" />
        </MDBox>
      )}
      <MDBox
        p={3}
        component="form"
        role="form"
        onSubmit={handleSubmit(
          async ({ logo, ...rest }: UpdateCompanyInput | CreateCompanyInput) => {
            const result = await onSubmit({ ...rest, logo });
            // @ts-expect-error: FIX update types
            if (result.success) {
              reset();
              // @ts-expect-error: FIX update types
              onSuccess(result.data);
            }
          }
        )}
      >
        <MDTypography variant="h5">Company Information</MDTypography>
        <MDBox mt={1}>
          {action === "upsert" && <input type="hidden" name="id" {...register("id")} />}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Name"
                placeholder="Name"
                error={errors.name}
                {...register("name")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomSelect
                control={control}
                name={"status"}
                data={enumToValueOptions(CompanyStatus)}
                label={"Status"}
                fullWidth
              />
              {errors?.status?.message && (
                <MDTypography variant="caption" color="error">
                  {errors?.status?.message}
                </MDTypography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Website"
                placeholder="Website"
                error={errors.website}
                {...register("website")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Email"
                placeholder="Email"
                error={errors.email}
                {...register("email")}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={phoneInputStyles}>
              <Controller
                control={control}
                name="phone"
                render={({ field }) => <PhoneInput label="Phone" error={errors.phone} {...field} />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Address Line 1"
                placeholder="Address Line 1"
                error={errors.addressLine1}
                {...register("addressLine1")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Address Line 2"
                placeholder="Address Line 2"
                error={errors.addressLine2}
                {...register("addressLine2")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Address City"
                placeholder="Address City"
                error={errors.addressCity}
                {...register("addressCity")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StateSelect
                name="addressState"
                label="Address State"
                countryFieldName="addressCountry"
                control={control}
                setValue={setValue}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type="text"
                label="Address Zip"
                placeholder="Address Zip"
                error={errors.addressZip}
                {...register("addressZip")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CountrySelect
                name="addressCountry"
                label="Address Country"
                defaultValue="US"
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="logo"
                render={({ field }) => (
                  <LogoUpload
                    sendPath={(path) => field.onChange(path)}
                    label="Logo"
                    watcher={watchLogo}
                    error={errors.logo}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="h5">Notification Settings</MDTypography>
              <MDTypography variant="h6" style={{ fontWeight: "normal" }}>
                What type of notifications do you want to send to this contact?
              </MDTypography>
              <MDBox display="flex" gap={2} mt={2}>
                {notificationTypes.map((type) => (
                  <FormControlLabel
                    key={type}
                    control={
                      <Checkbox
                        name={type.toLowerCase()}
                        checked={isNotificationSelected(type)}
                        {...register(`notificationTypes.${type}`)}
                        onChange={(event) => {
                          handleNotificationChange(type, event.target.checked);
                        }}
                      />
                    }
                    label={type
                      .replace("_", " ")
                      .toLowerCase()
                      .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())}
                  />
                ))}
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox>
                <MDTypography variant="h5">Notes</MDTypography>
              </MDBox>
              <Controller
                control={control}
                name="notes"
                render={({ field }) => (
                  <WYSIWYG content={field.value} onChange={(note) => field.onChange(note)} />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              {errors?.contacts?.[action] && !Array.isArray(errors?.contacts?.[action]) && (
                <MDBox mb={2}>
                  <MDAlert color="error" variant="caption">
                    {errors.contacts[action].message}
                  </MDAlert>
                </MDBox>
              )}
              <Card>
                <MDBox p={3}>
                  {fields.map((field, index) => {
                    const watchPrimary = watch(`contacts.${action}.${index}.primary`);

                    return (
                      <>
                        {action === "upsert" && <input type="hidden" name="id" value={field.id} />}
                        <Grid container spacing={3} key={field.id} mt={index === 0 ? 0 : 2}>
                          <Grid item xs={12} display="flex" alignItems="center">
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              gap="2"
                              width="100%"
                              alignItems="center"
                            >
                              <MDTypography variant="h6">
                                {(action === "create" && index === 0) ||
                                (action === "upsert" && watchPrimary === true)
                                  ? "Primary Contact (Required)"
                                  : `Other Contact`}
                              </MDTypography>
                              <FormControlLabel
                                sx={{ display: "flex", alignItems: "center" }}
                                control={<Checkbox />}
                                onChange={handleCopyInfo}
                                label="Copy Company Info to Contact"
                              />
                            </Box>

                            {(action === "create" && index > 0) ||
                            (action === "upsert" && watchPrimary !== true) ? (
                              <IconButton
                                onClick={() => {
                                  remove(index);
                                }}
                              >
                                <Icon fontSize="large" color="error">
                                  delete
                                </Icon>
                              </IconButton>
                            ) : null}
                          </Grid>
                          {!watchPrimary && action === "upsert" && (
                            <Grid item xs={12}>
                              <Controller
                                name={`contacts.upsert.${index}.primary`}
                                control={control}
                                render={({ field: inputField }) => (
                                  <FormGroup>
                                    <FormControlLabel
                                      disabled={inputField.value}
                                      control={<Switch disabled={inputField.value} />}
                                      label={"Make Primary"}
                                      checked={inputField.value}
                                      onChange={(e, checked) => {
                                        inputField.onChange(checked);
                                        updatePrimaryFields(index);
                                      }}
                                    />
                                  </FormGroup>
                                )}
                              />
                            </Grid>
                          )}
                          <Grid item xs={12} sm={6}>
                            <FormField
                              placeholder="Title"
                              defaultValue={field.title}
                              error={errors.contacts?.[action]?.[index]?.title}
                              {...register(`contacts.${action}.${index}.title` as const)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              placeholder="First Name"
                              defaultValue={field.firstName}
                              error={errors.contacts?.[action]?.[index]?.firstName}
                              {...register(`contacts.${action}.${index}.firstName` as const)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              placeholder="Last Name"
                              defaultValue={field.lastName}
                              error={errors.contacts?.[action]?.[index]?.lastName}
                              {...register(`contacts.${action}.${index}.lastName` as const)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              placeholder="Role"
                              defaultValue={field.role}
                              error={errors.contacts?.[action]?.[index]?.role}
                              {...register(`contacts.${action}.${index}.role` as const)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} sx={phoneInputStyles}>
                            <Controller
                              control={control}
                              name={`contacts.${action}.${index}.phone` as const}
                              render={({ field }) => (
                                <PhoneInput
                                  label="Phone"
                                  error={errors.contacts?.[action]?.[index]?.phone}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              placeholder="Email"
                              defaultValue={field.email}
                              error={errors.contacts?.[action]?.[index]?.email}
                              {...register(`contacts.${action}.${index}.email` as const)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Controller
                              name={`contacts.${action}.${index}.SMSNotifications` as const}
                              control={control}
                              render={({ field }) => (
                                <>
                                  <MDTypography variant="caption">SMS Notifications</MDTypography>
                                  <Switch
                                    {...field}
                                    checked={field?.value ?? true}
                                    disabled={company?.contacts[index]?.SMSNotificationsReadOnly}
                                  />
                                </>
                              )}
                            />
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
                  <Grid item xs={12} display="flex" justifyContent={"space-between"} pt={6}>
                    <MDButton
                      type="button"
                      variant="gradient"
                      color="secondary"
                      onClick={() => {
                        append({ ...defaultValuesSecondaryContact });
                      }}
                    >
                      Add Contact
                    </MDButton>

                    <MDButton type="submit" variant="gradient" color="info" disabled={isLoading}>
                      {action === "create" ? "Create" : "Update"}
                    </MDButton>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </div>
  );
};

export default CompanyForm;
