import { TextareaAutosize, FormHelperText } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Modal from "modules/Modal/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { JobPhaseAutocomplete } from "components/JobPhaseScrollAsyncAutocomplete/JobPhaseAutocomplete";
import ScrollAsyncAutocomplete from "components/ScrollAsyncAutocomplete/ScrollAsyncAutocomplete";
import useGetJobPhaseLazy from "hooks/jobs/job-phases/useGetJobPhaseLazy";
import ClearIcon from "@mui/icons-material/Clear";
import { useGetTimeBuckets } from "hooks/timeTracking/useGetTimeBuckets";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import dayjs from "dayjs";
import { zodResolver } from "@hookform/resolvers/zod";
import { schema, TimeEntryCreateSchema } from "DDD/action-objects/TimeEntryCreate";
import useModalState from "hooks/useModalState";
import { AddCircle } from "@mui/icons-material";
import { useEffect, useCallback } from "react";
import { useCreateTimeEntry } from "hooks/timeTracking/useCreateTimeEntry";
import OrganizationUsersOptions from "modules/organization/OrganizationUsersOptions/OrganizationUsersOptions";
import AutoComplete from "modules/AutoComplete/AutoComplete";
import { v4 as uuidv4 } from "uuid";

const datePickerStyles = {
  height: "32px",
  width: "200px",
  fontSize: "0.875rem",
  borderRadius: 2,
  border: "1px solid #ccc",
  color: "#344767",
  padding: "25px 13px",
  "&:focus-visible": {
    outline: "none",
  },
};

export default function CreateTimeEntryModal() {
  const { open, onClose, onOpen } = useModalState();
  const [createTimeEntry] = useCreateTimeEntry();
  const [getJobPhase, { data: jobPhaseData, loading: jobPhaseLoading }] = useGetJobPhaseLazy(true);
  const { data: timeBuckets, loading: timeBucketsLoading } = useGetTimeBuckets();

  const defaultValues = {
    startTime: new Date(),
    endTime: new Date(),
    notes: "",
    jobPhaseId: null,
    timeBucketId: null,
    userId: null,
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<TimeEntryCreateSchema>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const handleClose = useCallback(() => {
    reset(defaultValues);
    onClose();
  }, [reset, onClose]);

  const jobPhaseId = watch("jobPhaseId");

  useEffect(() => {
    if (jobPhaseId) {
      getJobPhase(jobPhaseId);
    }
  }, [jobPhaseId]);

  const onSubmit = async (data: TimeEntryCreateSchema) => {
    const result = await createTimeEntry({
      variables: {
        input: {
          uuid: uuidv4(),
          forDate: dayjs(data.startTime).format("YYYY-MM-DD"),
          startTime: dayjs(data.startTime).format("YYYY-MM-DD HH:mm:ss"),
          endTime: dayjs(data.endTime).format("YYYY-MM-DD HH:mm:ss"),
          notes: data.notes,
          jobPhaseId: data.jobPhaseId,
          timeBucketId: data.timeBucketId,
          userId: data.userId,
        },
      },
    });

    if (result.success) {
      handleClose();
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} styles={{ overflow: "auto", maxWidth: "568px" }}>
        <MDBox p={3}>
          <MDTypography variant="h6" mb={3}>
            Create Time Entry
          </MDTypography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <MDBox mb={2}>
              <JobPhaseAutocomplete>
                {({ options, queryLoading, pagination, handleSearch }) => (
                  <Controller
                    control={control}
                    name="jobPhaseId"
                    render={({ field: { onChange, value } }) => (
                      <ScrollAsyncAutocomplete
                        label="Select Job Phase"
                        value={options.find((x) => x.value === value)}
                        onChange={onChange}
                        options={options}
                        loading={queryLoading}
                        hasMore={pagination.hasNextPage}
                        search={handleSearch}
                        onLoadMore={pagination.loadMore}
                      />
                    )}
                  />
                )}
              </JobPhaseAutocomplete>
              {jobPhaseId && (
                <MDBox mt={1}>
                  <MDTypography
                    variant="button"
                    color="info"
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                      lineHeight: 1,
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                    onClick={() => {
                      setValue("jobPhaseId", null);
                    }}
                  >
                    <ClearIcon fontSize="small" sx={{ display: "flex" }} />
                    Clear Selection
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>

            <MDBox mb={3}>
              <Controller
                control={control}
                name="timeBucketId"
                render={({ field }) => (
                  <>
                    <CustomSelect
                      label="Time Bucket"
                      control={control}
                      disabled={timeBucketsLoading}
                      selectVariant="outlined"
                      fullWidth
                      data={
                        timeBuckets?.map((bucket) => ({
                          value: bucket.id,
                          label: bucket.name,
                        })) || []
                      }
                      {...field}
                    />
                    {field.value && (
                      <MDBox mt={1}>
                        <MDTypography
                          variant="button"
                          color="info"
                          sx={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            gap: 0.5,
                            lineHeight: 1,
                            "&:hover": {
                              color: "primary.main",
                            },
                          }}
                          onClick={() => {
                            field.onChange(null);
                          }}
                        >
                          <ClearIcon fontSize="small" sx={{ display: "flex" }} />
                          Clear Selection
                        </MDTypography>
                      </MDBox>
                    )}
                  </>
                )}
              />
            </MDBox>

            <MDBox
              display="flex"
              flexDirection="column"
              sx={{
                ".date-input": datePickerStyles,
                mb: 2,
              }}
            >
              <label style={{ fontSize: "0.875rem", color: "#7b809a" }}>Start Time</label>
              <Controller
                control={control}
                name="startTime"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    selected={value}
                    onChange={onChange}
                    showTimeSelect
                    timeIntervals={15}
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    className="date-input"
                  />
                )}
              />
              <FormHelperText error>{errors.startTime?.message}</FormHelperText>
            </MDBox>

            <MDBox
              display="flex"
              flexDirection="column"
              sx={{
                ".date-input": datePickerStyles,
                mb: 2,
              }}
            >
              <label style={{ fontSize: "0.875rem", color: "#7b809a" }}>End Time</label>
              <Controller
                control={control}
                name="endTime"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    selected={value}
                    onChange={onChange}
                    showTimeSelect
                    timeIntervals={15}
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    className="date-input"
                  />
                )}
              />
              <FormHelperText error>{errors.endTime?.message}</FormHelperText>
            </MDBox>

            <MDBox mb={3}>
              <OrganizationUsersOptions queryOverride={{ first: 1000 }}>
                {({ organizationUsersOptions, loading }) => (
                  <Controller
                    control={control}
                    name="userId"
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <AutoComplete
                          options={organizationUsersOptions}
                          loading={loading}
                          variant="outlined"
                          field={{
                            onChange: field.onChange,
                            onBlur: field.onBlur,
                            value: field.value,
                            name: field.name,
                            ref: field.ref,
                          }}
                          error={error}
                          label="Select User"
                        />
                        {field.value && (
                          <MDBox mt={1}>
                            <MDTypography
                              variant="button"
                              color="info"
                              sx={{
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                gap: 0.5,
                                lineHeight: 1,
                                "&:hover": {
                                  color: "primary.main",
                                },
                              }}
                              onClick={() => {
                                field.onChange(null);
                              }}
                            >
                              <ClearIcon fontSize="small" sx={{ display: "flex" }} />
                              Clear Selection
                            </MDTypography>
                          </MDBox>
                        )}
                      </>
                    )}
                  />
                )}
              </OrganizationUsersOptions>
            </MDBox>

            {/* Notes */}
            <TextareaAutosize
              minRows={5}
              style={{
                width: "100%",
                borderRadius: "10px",
                border: "1px solid #ccc",
                padding: "10px",
              }}
              {...register("notes")}
            />

            <MDButton type="submit" variant="gradient" color="success">
              Create
            </MDButton>
          </form>
        </MDBox>
      </Modal>
      <MDButton variant="gradient" startIcon={<AddCircle />} onClick={onOpen}>
        Create Time Entry
      </MDButton>
    </>
  );
}
