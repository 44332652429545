import ScrollAsyncAutocomplete from "components/ScrollAsyncAutocomplete/ScrollAsyncAutocomplete";
import { SelectOptions } from "components/Shared/CustomSelect/CustomSelect";
import { JobPhase } from "generated/graphql";
import useGetJobPhases from "hooks/jobs/job-phases/useGetJobPhases";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import usePaginatedResources, { Pagination } from "hooks/usePaginatedResources";
import React, { useMemo } from "react";

interface JobPhaseAutocompleteProps {
  selectedJobPhase?: JobPhase | null;
  children: ({
    options,
    queryLoading,
    pagination,
    handleSearch,
  }: {
    options: SelectOptions;
    queryLoading: boolean;
    pagination: Pagination;
    handleSearch: (term: string) => void;
  }) => React.ReactNode;
}

export const JobPhaseAutocomplete = ({ selectedJobPhase, children }: JobPhaseAutocompleteProps) => {
  const { filtering, sorting, offset } = usePaginatedVariables({
    initialSortDirection: "desc",
    initialSortKey: "created_at",
    initialStartsAt: "",
    initialCompanyId: null,
  });

  const { jobPhasesData, queryLoading, paginationData } = useGetJobPhases({
    filtering,
    sorting,
    offset,
  });

  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo: paginationData,
  });

  const jobPhases = useMemo(() => {
    const jobPhases = [];
    const selectedJobPhaseExists =
      selectedJobPhase &&
      // @ts-ignore
      (jobPhasesData ?? []).filter((phase) => phase.id === selectedJobPhase.id).length > 0;

    if (!selectedJobPhaseExists && selectedJobPhase && !filtering.debouncedSearchTerm) {
      jobPhases.push(selectedJobPhase);
    }

    return [...jobPhases, ...(jobPhasesData ?? [])];
  }, [jobPhasesData, selectedJobPhase, filtering.debouncedSearchTerm]);

  const options = useMemo(
    () =>
      jobPhases?.map((phase) => ({
        value: phase.id,
        label: `${phase.proposalStage?.name} - ${phase.organizationProductTypeName}`,
      })) || [],
    [jobPhases]
  );

  return (
    <>
      {children({
        options,
        queryLoading,
        pagination,
        handleSearch: filtering.setSearchTerm,
      })}
    </>
  );
};
