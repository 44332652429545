import { useState } from "react";
import { IconButton, Tooltip, TextField, Icon } from "@mui/material";
import MDButton from "components/MDButton";
import { useCreateTimeBucket } from "hooks/timeTracking/useCreateTimeBucket";
import getLocalString from "constants/Localization";
import MDBox from "components/MDBox";

const CreateTimeBucket = () => {
  const [bucketName, setBucketName] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [createTimeBucket] = useCreateTimeBucket();

  const handleSave = async () => {
    if (bucketName.trim() === "") return;

    const result = await createTimeBucket({
      variables: {
        input: {
          name: bucketName.trim(),
        },
      },
    });

    if (result.success) {
      setBucketName("");
      setIsEditing(false);
    }
  };

  return (
    <>
      {isEditing ? (
        <MDBox display="flex" alignItems="center">
          <TextField
            value={bucketName}
            onChange={(e) => setBucketName(e.target.value)}
            placeholder="Enter Time Bucket Name"
            variant="outlined"
            size="small"
          />
          <Tooltip title="Save">
            <IconButton onClick={handleSave} color="success">
              <Icon>save</Icon>
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel">
            <IconButton onClick={() => setIsEditing(false)} color="error">
              <Icon>close</Icon>
            </IconButton>
          </Tooltip>
        </MDBox>
      ) : (
        <MDButton onClick={() => setIsEditing(true)} variant="gradient" color="success">
          Create Time Bucket
        </MDButton>
      )}
    </>
  );
};

export default CreateTimeBucket;
