import { useCallback, useMemo } from "react";
import { useGetTimeEntries } from "./useGetTimeEntries";
import { usePaginatedVariables } from "hooks/strings/usePaginatedVariables";
import usePaginatedResources from "hooks/usePaginatedResources";
import { sortDirectionMap } from "constants/sortDirectionMap";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import {
  timeEntriesInitialSortDirectionKey,
  timeEntriesInitialSortKeyKey,
  timeEntriesInitialSortKeyValue,
  timeEntriesSortKeyMap,
} from "constants/timeEntries/sortKeys";
import TimeEntryActionsCell from "modules/TimeEntries/TimeEntryActionsCell";
import { JobPhaseAutocomplete } from "components/JobPhaseScrollAsyncAutocomplete/JobPhaseAutocomplete";
import ScrollAsyncAutocomplete from "components/ScrollAsyncAutocomplete/ScrollAsyncAutocomplete";
import MDTypography from "components/MDTypography";
import OrganizationUsersOptions from "modules/organization/OrganizationUsersOptions/OrganizationUsersOptions";
import AutoComplete from "modules/AutoComplete/AutoComplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

dayjs.extend(duration);

export const useTimeEntriesData = () => {
  const { filtering, offset, sorting } = usePaginatedVariables({
    initialSortDirection: timeEntriesInitialSortDirectionKey,
    initialSortKey: timeEntriesInitialSortKeyKey,
    initialJobPhaseId: null,
    initialUserID: null,
    initialForDate: null,
  });

  const { data, loading, error, paginatorInfo } = useGetTimeEntries({
    variables: {
      page: offset.page,
      first: offset.first,
      sortKey: timeEntriesSortKeyMap[sorting.sortKey] || timeEntriesInitialSortKeyValue,
      sortDirection: sortDirectionMap[sorting.sortDirection],
      jobPhaseId: filtering.jobPhaseId,
      userId: filtering.userID,
      forDate: filtering.forDate,
    },
  });

  const pagination = usePaginatedResources({
    paginate: offset.paginate,
    paginatorInfo,
  });

  const JobPhaseFilter = useCallback(() => {
    return (
      <JobPhaseAutocomplete>
        {({ options, queryLoading, pagination, handleSearch }) => (
          <>
            <MDBox
              display="flex"
              width="100%"
              alignItems="center"
              sx={{ "& > div:first-of-type": { width: filtering.jobPhaseId ? "96%" : "100%" } }}
            >
              <ScrollAsyncAutocomplete
                label="Filter by Job Phase"
                value={options.find((x) => x.value === filtering.jobPhaseId)}
                onChange={(value) => filtering.setJobPhaseId(value as string)}
                options={options}
                loading={queryLoading}
                hasMore={pagination.hasNextPage}
                search={handleSearch}
                onLoadMore={pagination.loadMore}
              />
              {filtering.jobPhaseId && (
                <MDBox ml={1}>
                  <MDTypography
                    component="i"
                    variant="body2"
                    color="secondary"
                    onClick={() => filtering.setJobPhaseId(null)}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        color: "error.main",
                      },
                    }}
                  >
                    &#x2715;
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>
          </>
        )}
      </JobPhaseAutocomplete>
    );
  }, [filtering]);

  const UserFilter = useCallback(() => {
    return (
      <OrganizationUsersOptions queryOverride={{ first: 1000 }}>
        {({ organizationUsersOptions, loading }) => (
          <AutoComplete
            options={organizationUsersOptions}
            loading={loading}
            variant="outlined"
            field={{
              onChange: (value) => filtering.setUserID(value as string),
              onBlur: () => {},
              value: filtering.userID,
              name: "userID",
              ref: null,
            }}
            error={null}
            placeholder="Select User"
          />
        )}
      </OrganizationUsersOptions>
    );
  }, [filtering.setUserID, filtering.userID]);

  const DateFilter = useCallback(() => {
    return (
      <MDBox
        width="100%"
        sx={{
          "& .react-datepicker-wrapper": {
            width: "100%",
            maxWidth: "100%",
          },
        }}
      >
        <DatePicker
          selected={filtering.forDate ? new Date(filtering.forDate) : null}
          onChange={(date) => filtering.setForDate(date ? dayjs(date).format("YYYY-MM-DD") : null)}
          dateFormat="MM/dd/yyyy"
          isClearable
          placeholderText="Select Date"
          customInput={
            <MDInput
              variant="outlined"
              label="Filter by Date"
              sx={{
                width: "100%",
                "& .MuiInputBase-root, & .MuiFormControl-root, & .MuiInputBase-input, & .MuiTextField-root":
                  {
                    height: "53px",
                    maxWidth: "100%",
                    width: "100%",
                  },
              }}
            />
          }
        />
      </MDBox>
    );
  }, [filtering.forDate, filtering.setForDate]);

  const columns = useMemo(() => {
    return [
      {
        Header: "Date",
        accessor: "forDate",
      },
      {
        Header: "User",
        accessor: "user.name",
      },
      {
        Header: "Job ID",
        accessor: "jobPhase.externalId",
      },
      {
        Header: "Job Name",
        accessor: "jobPhase.proposalStage.name",
        disableSortBy: true,
      },
      {
        Header: "Job Address",
        accessor: "jobPhase.address",
        disableSortBy: true,
      },
      {
        Header: "Clock In Time",
        accessor: "startTime",
      },
      {
        Header: "Clock Out Time",
        accessor: "endTime",
      },
      {
        Header: "Total Hours",
        accessor: "timeTrackedSeconds",
        Cell: ({ value }) => {
          return dayjs.duration(value, "seconds").format("H:mm");
        },
      },
      {
        Header: "Actions",
        accessor: "actions",
        align: "right",
        disableSortBy: true,
        Cell: TimeEntryActionsCell,
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    return { columns, rows: data };
  }, [columns, data]);

  return {
    data,
    loading,
    error,
    columns,
    tableData,
    pagination,
    filtering,
    paginatorInfo,
    sorting,
    JobPhaseFilter,
    UserFilter,
    DateFilter,
  } as const;
};
