import { TextareaAutosize, FormHelperText } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useEffect } from "react";
import Modal from "modules/Modal/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { JobPhaseAutocomplete } from "components/JobPhaseScrollAsyncAutocomplete/JobPhaseAutocomplete";
import { JobPhase } from "generated/graphql";
import ScrollAsyncAutocomplete from "components/ScrollAsyncAutocomplete/ScrollAsyncAutocomplete";
import useGetJobPhaseLazy from "hooks/jobs/job-phases/useGetJobPhaseLazy";
import ClearIcon from "@mui/icons-material/Clear";
import { useGetTimeBuckets } from "hooks/timeTracking/useGetTimeBuckets";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import { useUpdateTimeEntry } from "hooks/timeTracking/useUpdateTimeEntry";
import dayjs from "dayjs";
import { zodResolver } from "@hookform/resolvers/zod";
import { schema, TimeEntryUpdateSchema } from "DDD/action-objects/TimeEntryUpdate";

export default function EditTimeEntryModal({ open, onClose, entry }) {
  const [updateTimeEntry] = useUpdateTimeEntry();

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<TimeEntryUpdateSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      uuid: entry.uuid,
      startTime: new Date(entry.startTime),
      endTime: new Date(entry.endTime),
      notes: entry.notes,
      jobPhaseId: entry.jobPhase?.id,
      timeBucketId: entry.timeBucket?.id,
    },
  });

  const onSubmit = async (data: TimeEntryUpdateSchema) => {
    const result = await updateTimeEntry({
      variables: {
        input: {
          uuid: data.uuid,
          startTime: dayjs(data.startTime).format("YYYY-MM-DD HH:mm:ss"),
          endTime: dayjs(data.endTime).format("YYYY-MM-DD HH:mm:ss"),
          notes: data.notes,
          jobPhaseId: data.jobPhaseId,
          timeBucketId: data.timeBucketId,
        },
      },
    });

    if (result.success) {
      onClose();
    }
  };

  const datePickerStyles = {
    height: "32px",
    width: "200px",
    fontSize: "0.875rem",
    borderRadius: 2,
    border: "1px solid #ccc",
    color: "#344767",
    padding: "25px 13px",
    "&:focus-visible": {
      outline: "none",
    },
  };

  const [getJobPhase, { data: jobPhaseData, loading: jobPhaseLoading }] = useGetJobPhaseLazy(true);
  const jobPhaseId = watch("jobPhaseId");

  useEffect(() => {
    if (jobPhaseId) {
      getJobPhase(jobPhaseId);
    }
  }, [jobPhaseId]);

  const { data: timeBuckets, loading: timeBucketsLoading } = useGetTimeBuckets();

  return (
    <Modal open={open} onClose={onClose} styles={{ overflow: "auto", maxWidth: "568px" }}>
      <MDBox p={3}>
        <MDTypography variant="h6" mb={3}>
          Edit Time Entry
        </MDTypography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <MDBox mb={3}>
            <JobPhaseAutocomplete selectedJobPhase={jobPhaseData?.getJobPhase as JobPhase}>
              {({ options, queryLoading, pagination, handleSearch }) => (
                <Controller
                  control={control}
                  name="jobPhaseId"
                  render={({ field: { onChange, value } }) => (
                    <ScrollAsyncAutocomplete
                      label="Select Job Phase"
                      value={options.find((x) => x.value === value)}
                      onChange={onChange}
                      options={options}
                      loading={queryLoading}
                      hasMore={pagination.hasNextPage}
                      search={handleSearch}
                      onLoadMore={pagination.loadMore}
                    />
                  )}
                />
              )}
            </JobPhaseAutocomplete>
            {jobPhaseId && (
              <MDBox mt={1}>
                <MDTypography
                  variant="button"
                  color="info"
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                    lineHeight: 1,
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                  onClick={() => {
                    setValue("jobPhaseId", null);
                  }}
                >
                  <ClearIcon fontSize="small" sx={{ display: "flex" }} />
                  Clear Selection
                </MDTypography>
              </MDBox>
            )}
          </MDBox>

          <MDBox mb={3}>
            <MDTypography variant="body3">Selected Job Phase Details:</MDTypography>
            {jobPhaseId && jobPhaseData?.getJobPhase && (
              <MDBox>
                <MDTypography variant="body3" display="block" color="text">
                  ID: {jobPhaseData.getJobPhase.id}
                </MDTypography>
                <MDTypography variant="body3" display="block" color="text">
                  Phase: {jobPhaseData.getJobPhase.proposalStage?.name}
                </MDTypography>
                <MDTypography variant="body3" display="block" color="text">
                  Product Type: {jobPhaseData.getJobPhase.organizationProductTypeName}
                </MDTypography>
                <MDTypography variant="body3" display="block" color="text">
                  Address: {jobPhaseData.getJobPhase.address}
                </MDTypography>
              </MDBox>
            )}
          </MDBox>

          <MDBox mb={1} display="flex">
            <Controller
              control={control}
              name="timeBucketId"
              render={({ field }) => (
                <CustomSelect
                  label="Time Bucket"
                  control={control}
                  disabled={timeBucketsLoading}
                  selectVariant="outlined"
                  fullWidth
                  data={
                    timeBuckets?.map((bucket) => ({
                      value: bucket.id,
                      label: bucket.name,
                    })) || []
                  }
                  {...field}
                />
              )}
            />
          </MDBox>
          <MDBox mb={3}>
            {watch("timeBucketId") && (
              <MDBox>
                <MDTypography
                  variant="button"
                  color="info"
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                    lineHeight: 1,
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                  onClick={() => {
                    setValue("timeBucketId", null);
                  }}
                >
                  <ClearIcon fontSize="small" sx={{ display: "flex" }} />
                  Clear Selection
                </MDTypography>
              </MDBox>
            )}
          </MDBox>

          <MDBox
            display="flex"
            flexDirection="column"
            sx={{
              ".date-input": datePickerStyles,
              mb: 2,
            }}
          >
            <label style={{ fontSize: "0.875rem", color: "#7b809a" }}>Start Time</label>
            <Controller
              control={control}
              name="startTime"
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  selected={value}
                  onChange={onChange}
                  showTimeSelect
                  timeIntervals={15}
                  dateFormat="yyyy-MM-dd HH:mm:ss"
                  className="date-input"
                />
              )}
            />
            <FormHelperText error>{errors.startTime?.message}</FormHelperText>
          </MDBox>

          <MDBox
            display="flex"
            flexDirection="column"
            sx={{
              ".date-input": datePickerStyles,
              mb: 2,
            }}
          >
            <label style={{ fontSize: "0.875rem", color: "#7b809a" }}>End Time</label>
            <Controller
              control={control}
              name="endTime"
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  selected={value}
                  onChange={onChange}
                  showTimeSelect
                  timeIntervals={15}
                  dateFormat="yyyy-MM-dd HH:mm:ss"
                  className="date-input"
                />
              )}
            />
            <FormHelperText error>{errors.endTime?.message}</FormHelperText>
          </MDBox>

          <TextareaAutosize
            minRows={5}
            style={{
              width: "100%",
              borderRadius: "10px",
              border: "1px solid #ccc",
              padding: "10px",
            }}
            {...register("notes")}
          />

          <MDButton type="submit" variant="gradient" color="success">
            Save
          </MDButton>
        </form>
      </MDBox>
    </Modal>
  );
}
